import ApiHelper from './apiHelper';
import PerformanceType from '@/models/Performance';

export default class PerformanceDataApi {
    static async getData (year: string): Promise<Array<PerformanceType>> {
        return (await ApiHelper.post('GetClientPerformanceInputs', { year })).data as Promise<Array<PerformanceType>>;
    }

    static async saveData (year: string, saveData: Array<PerformanceType>): Promise<Array<PerformanceType>> {
        return (await ApiHelper.post('UpdateClientPerformance',  { data: saveData, year })).data as Promise<Array<PerformanceType>>;
    }
}
