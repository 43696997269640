































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import performanceDataApi from '@/api/performanceDataApi';
import dayjs from 'dayjs';
import ManagerList from '@/components/MonitorManager/ManagerList.vue';
import PerformanceTable from '@/components/MonitorManager/PerformanceTable.vue';
import BaseModal from '@/components/BaseModal.vue';
import UnSavedMessage from '@/components/MonitorManager/UnSavedMessage.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import cloneDeep from 'lodash.clonedeep';
import PerformanceType from '@/models/Performance';
import PerformanceItem from '@/models/PerformanceItem';
import BenchmarkPerformanceTickerIds from '@/models/BenchmarkPerformanceTickerIds';
import PartPeriodPerformance from '@/components/PartPeriodPerformance.vue';
import { IPartPeriodPerformance } from '@/interfaces/performance/IPartPeriodPerformance';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import { FORMAT_DATE_ISO_SHORT } from '@/constants';


@Component({
    components: {
        ManagerList,
        PerformanceTable,
        BaseModal,
        UnSavedMessage,
        LoadingOverlay,
        PartPeriodPerformance,
        BaseButton,
    },
})
export default class Performance extends Vue {
    @Prop({ type: String, required: true }) performanceType!: string;

    public isPPPFetchingData = false;

    public isFetchingData = false;

    public isToBeSaveModal = false;

    public originalPerformanceData: Array<PerformanceType> = [];

    public performanceDataWithPrefills: Array<PerformanceType> = [];

    public managerIds: number[] = [];

    public DATE_TODAY = dayjs().format(FORMAT_DATE_ISO_SHORT);

    public underlineWidth = 0;

    public underlineLeft = 0;

    public performancePeriodHeadings = [
        'Jan',
        'Feb',
        'Mar',
        'Q1',
        'Apr',
        'May',
        'Jun',
        'Q2',
        'Jul',
        'Aug',
        'Sep',
        'Q3',
        'Oct',
        'Nov',
        'Dec',
        'Q4',
    ];

    get pppEntityData (): PerformanceType | null {
        if (this.performanceType === 'managerPerformance') {
            const result = this.performanceDataWithPrefills.find(performanceDataWithPrefill => performanceDataWithPrefill.id === this.pppManagerId) ?? null;
            if (result) {
                const x = result.partPeriodPerformances.filter(partPeriodPerformance => partPeriodPerformance.benchmarkId === null);
                result.partPeriodPerformances = x;
                return result;
            }
            return null;
        }
        else if (this.performanceType === 'benchmarkPerformance') {
            // find the entity by benchmark id
            const pppEntity = this.performanceDataWithPrefills.find(performanceDataWithPrefill => performanceDataWithPrefill.benchmarkId === this.benchmarkId) ?? null;
            if (pppEntity) {
                return pppEntity;
            }
        }
        return null;
    }

    get pppPerformancePeriodTags (): string[] | null {

        if (this.performanceType === 'managerPerformance') {
            return this.performanceDataWithPrefills.find(performanceDataWithPrefill => performanceDataWithPrefill.id === this.pppManagerId)?.partPeriodPerformances.map(partPeriodPerformance => partPeriodPerformance.performancePeriodTag) ?? null;
        }
        if (this.performanceType === 'benchmarkPerformance') {
            const result = this.performanceDataWithPrefills.find(performanceDataWithPrefill => performanceDataWithPrefill.benchmarkId === this.benchmarkId) ?? null;
            if (result) {
                const x = result.partPeriodPerformances.filter(partPeriodPerformance => partPeriodPerformance.benchmarkId === null);
                result.partPeriodPerformances = x;
                return result.partPeriodPerformances.map(partPeriodPerformance => partPeriodPerformance.performancePeriodTag);
            }

        }
        return [];
    }


    pppKey = 0

    pppManagerId = -1;

    benchmarkId: number | null = null;

    pppTickerId: number | null = null;


    openPPPModal (managerId: number, benchmarkId: number | null = null, pppTickerId: number | null = null): void {
        this.pppManagerId = managerId;
        this.benchmarkId = benchmarkId;
        this.pppTickerId = pppTickerId;
        this.isPPPModalOpen = true;
    }

    public isPPPModalOpen = false;

    closePPPModal (): void {
        this.pppManagerId = -1;
        this.benchmarkId = null;
        this.pppTickerId = null;
        this.isPPPModalOpen = false;
        this.pppAPIValidationErrorMessage = '';
    }

    pppAPIValidationErrorMessage = '';

    async savePPP (partPeriodPerformances: IPartPeriodPerformance[]): Promise<void> {
        this.pppAPIValidationErrorMessage = '';
        const pppPayload = partPeriodPerformances.map((partPeriodPerformance: IPartPeriodPerformance) => {
            return {
                startDate: partPeriodPerformance.dates[0],
                effectiveDate: partPeriodPerformance.dates[1],
                performancePeriodTag: null, // not needed for save
                rowID: partPeriodPerformance.rowId,
                tickerID: partPeriodPerformance.tickerId,
                tickerValue: partPeriodPerformance.tickerValue,
                type: 'PPPRM',
                new: partPeriodPerformance,
                isDelete: partPeriodPerformance.isDelete,
                benchmarkId: this.benchmarkId
            };
        });
        const updatedItems: PerformanceType[] = [
            {
                name: this.pppEntityData?.name,
                performance: [...pppPayload],
                id: this.pppManagerId,
                benchmarkTickerIds: this.pppEntityData?.benchmarkTickerIds ?? null,
                rollForwardTickerIds: this.pppEntityData.rollForwardTickerIds ?? null,
                entityTypeId: this.pppEntityData?.entityTypeId ?? null,
            }
        ];
        this.isPPPFetchingData = true;

        try {
            const response = await performanceDataApi.saveData(this.selectedYear, updatedItems);
            this.originalPerformanceData = response;
            this.isPPPFetchingData = false;
            this.pppKey++;
            this.createPerformanceTable();
            this.closePPPModal();
        } catch (error) {
            if (error?.response?.data?.Message) {
                this.pppAPIValidationErrorMessage = error.response.data.Message;
            } else {
                this.pppAPIValidationErrorMessage = 'There was an error saving the data';
            }
        } finally {
            this.isPPPFetchingData = false;
        }

    }

    public selectedYear = new Date().getFullYear().toString();

    public managerInFocus = -1;


    created (): void {
        this.getAllData();
    }

    @Watch('performanceType')
    private updateTableWithNewAPI () {
        this.getAllData();
    }

    public async getAllData (): Promise<void> {
        if (this.unsavedData) {
            this.$store.commit('updateShowUnSavedMessage', true);
            return;
        }

        this.isFetchingData = true;

        try {
            const originalPerformanceData = await performanceDataApi.getData(this.selectedYear);

            this.originalPerformanceData = originalPerformanceData;
            this.createPerformanceTable();
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error fetching the data');
        } finally {
            this.isFetchingData = false;
        }

    }

    public async saveData (): Promise<void> {
        this.$store.commit('updateShowUnSavedMessage', false);
        this.$store.commit('updateUnsavedData', false);

        this.isFetchingData = true;
        try {
            if (this.dataToBeSaved.length > 0) {
                const response = await performanceDataApi.saveData(this.selectedYear, this.dataToBeSaved);
                this.originalPerformanceData = response;
                this.createPerformanceTable();
            }
        } catch (error) {
            this.getAllData();
            const message = error?.response?.data?.Message;
            if (message) {
                this.$store.dispatch('pushNetworkValidationMessage', message);
            } else {
                this.$store.dispatch('pushNetworkErrorMessage', 'There was an error saving the data');
            }
        } finally {
            this.isFetchingData = false;
        }
    }

    public discardChanges (): void {
        this.$store.commit('updateUnsavedData', false);
        this.$store.commit('updateShowUnSavedMessage', false);
        this.getAllData();
    }

    get isDataToBeSaved (): boolean {
        if (this.dataToBeSaved.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    get dataToBeSaved (): Array<PerformanceType> {
        const managersWithDataToBeSaved: Array<PerformanceType> = [];

        const idKey = this.performanceType === 'managerPerformance' ? 'id' : 'benchmarkId';

        this.performanceDataWithPrefills.forEach((manager: PerformanceType) => {
            manager.performance.forEach((assetItem: PerformanceItem<'' | number>) => {
                if (assetItem.meta.edited || assetItem.isDelete) {
                    const managerAlreadyInList = managersWithDataToBeSaved.find((managerInList: PerformanceType) => managerInList[idKey] === manager[idKey]);
                    const managerAlreadyInListIndex = managersWithDataToBeSaved.indexOf(managerAlreadyInList);
                    if (managerAlreadyInList) {
                        const assetAlreadyInListIndex = managerAlreadyInList.performance.findIndex((asset: PerformanceItem<string | number>) =>
                            assetItem.type === asset.type && assetItem.effectiveDate === asset.effectiveDate
                        );
                        if (assetAlreadyInListIndex >= 0) {
                            managerAlreadyInList.performance[managerAlreadyInListIndex] = assetItem;
                        } else {
                            managerAlreadyInList.performance.push(assetItem);
                        }
                    } else {
                        const newManager: PerformanceType = cloneDeep(manager);
                        newManager.performance = [assetItem];
                        managersWithDataToBeSaved.push(newManager);
                    }
                }
            });
        });

        managersWithDataToBeSaved.forEach((manager) => {
            delete manager.benchmarkId;
            delete manager.pppTickerId;
        });

        return managersWithDataToBeSaved;
    }


    public goToManagerSettings (): void {
        this.$router.push('/manager-settings');
    }

    public goToBulkOperations (): void {
        this.$router.push('/bulk-operations');
    }

    public highlightManagerInFocus (rowIndex: number): void {
        this.managerInFocus = rowIndex;
    }

    postfixNameWithDash (name: string): string {
        if (name.length) return `${name} &mdash; `;
        return name;
    }

    createPerformanceTable (): void {
        const newManagerDataWithPrefills: PerformanceType[] = [];
        if (!this.originalPerformanceData) return;

        let rowIndex = -1;

        this.originalPerformanceData.forEach((manager: PerformanceType) => {

            if (this.performanceType === 'managerPerformance') {
                rowIndex++;
                const managerWithoutPerformance = cloneDeep(manager);
                const managerPartPeriodPerformances = manager.performance.filter((performanceItem: PerformanceItem) => performanceItem.type === 'PPPRM' && performanceItem.tickerID === manager.rollForwardTickerIds?.partPeriodTickerId);
                managerWithoutPerformance.partPeriodPerformances = managerPartPeriodPerformances;
                managerWithoutPerformance.pppTickerId = manager.rollForwardTickerIds?.partPeriodTickerId ?? null;
                managerWithoutPerformance.performance = [];
                newManagerDataWithPrefills.push(managerWithoutPerformance);
                this.performancePeriodHeadings.forEach((period: string) => {
                    this.addPerformanceToRow(manager, period, newManagerDataWithPrefills, rowIndex);
                });

            } else {

                if (manager.benchmarkTickerIds.primaryBenchmark) {
                    rowIndex++;
                    const managerBenchmarkWithoutPerformance = cloneDeep(manager);
                    managerBenchmarkWithoutPerformance.name = `${this.postfixNameWithDash(manager.name)}${manager.benchmarkTickerIds.primaryBenchmark.benchmarkName}`;
                    managerBenchmarkWithoutPerformance.performance = [];
                    const managerPartPeriodPerformances = manager.performance.filter((performanceItem: PerformanceItem) => performanceItem.type === 'PPPRM' && performanceItem.tickerID === manager.benchmarkTickerIds.primaryBenchmark?.partPeriodTickerId);
                    managerBenchmarkWithoutPerformance.partPeriodPerformances = managerPartPeriodPerformances;
                    managerBenchmarkWithoutPerformance.benchmarkId = manager.benchmarkTickerIds.primaryBenchmark?.benchmarkId ?? null;
                    managerBenchmarkWithoutPerformance.pppTickerId = manager.benchmarkTickerIds.primaryBenchmark?.partPeriodTickerId ?? null;
                    newManagerDataWithPrefills.push(managerBenchmarkWithoutPerformance);
                    this.performancePeriodHeadings.forEach((period: string) => {
                        this.addPerformanceToRow(manager, period, newManagerDataWithPrefills, rowIndex, manager.benchmarkTickerIds.primaryBenchmark?.benchmarkId);
                    });
                }

                manager.benchmarkTickerIds.secondaryBenchmarks.forEach(bm => {
                    rowIndex++;
                    const managerBenchmarkWithoutPerformance = cloneDeep(manager);
                    managerBenchmarkWithoutPerformance.name = `${this.postfixNameWithDash(manager.name)}${bm.benchmarkName}`;
                    managerBenchmarkWithoutPerformance.performance = [];
                    const managerPartPeriodPerformances = manager.performance.filter((performanceItem: PerformanceItem) => performanceItem.type === 'PPPRM' && performanceItem.tickerID === bm.partPeriodTickerId);
                    managerBenchmarkWithoutPerformance.partPeriodPerformances = managerPartPeriodPerformances;
                    managerBenchmarkWithoutPerformance.benchmarkId = bm.benchmarkId;
                    managerBenchmarkWithoutPerformance.pppTickerId = bm.partPeriodTickerId;
                    newManagerDataWithPrefills.push(managerBenchmarkWithoutPerformance);
                    this.performancePeriodHeadings.forEach((period: string) => {
                        this.addPerformanceToRow(manager, period, newManagerDataWithPrefills, rowIndex, bm.benchmarkId);
                    });
                });
            }
        });

        Vue.set(this, 'performanceDataWithPrefills', newManagerDataWithPrefills);
        Vue.set(this, 'managerIds', newManagerDataWithPrefills.map(x => x.id));
    }

    private addPerformanceToRow (manager: PerformanceType, period: string, newManagerDataWithPrefills: PerformanceType[], rowIndex: number, benchmarkId: number | null = null) {
        let managerHasPeriodIndex = -1;
        let tickerId = -1;
        let type = '';
        let targetBenchmark: BenchmarkPerformanceTickerIds | null = null;
        const isQuarter = period.charAt(0) === 'Q';
        type = isQuarter ? '3MPRM' : '1MPRM';

        if (this.performanceType === 'managerPerformance') {
            managerHasPeriodIndex = manager.performance.findIndex((p: PerformanceItem<string | number>) => p.performancePeriodTag === period &&
                (p.tickerID === manager.rollForwardTickerIds?.oneMonthTickerId || p.tickerID === manager.rollForwardTickerIds?.threeMonthTickerId));
            tickerId = isQuarter ? manager.rollForwardTickerIds.threeMonthTickerId : manager.rollForwardTickerIds.oneMonthTickerId;
        } else {
            if (benchmarkId === null) {
                targetBenchmark = manager.benchmarkTickerIds.primaryBenchmark ?? null;
            } else {
                targetBenchmark = manager.benchmarkTickerIds.secondaryBenchmarks.find(bm => bm.benchmarkId === benchmarkId);
                if (!targetBenchmark) {
                    targetBenchmark = manager.benchmarkTickerIds.primaryBenchmark ?? null;
                }
            }

            managerHasPeriodIndex = manager.performance.findIndex((performance: PerformanceItem<string | number>) => {
                return (
                    performance.performancePeriodTag === period &&
                    (performance.tickerID === targetBenchmark?.oneMonthTickerId || performance.tickerID === targetBenchmark?.threeMonthTickerId)
                );
            });
            tickerId = isQuarter ? targetBenchmark?.threeMonthTickerId : targetBenchmark?.oneMonthTickerId;
            tickerId = (tickerId === -1 || tickerId === undefined) ? null : tickerId;
        }

        if (managerHasPeriodIndex >= 0) {
            const copiedPerformanceItem = new PerformanceItem(
                manager.performance[managerHasPeriodIndex].effectiveDate,
                manager.performance[managerHasPeriodIndex].performancePeriodTag,
                manager.performance[managerHasPeriodIndex].rowID,
                manager.performance[managerHasPeriodIndex].tickerID,
                manager.performance[managerHasPeriodIndex].tickerValue,
                manager.performance[managerHasPeriodIndex].type,
                manager.performance[managerHasPeriodIndex].isDelete,
                {
                    new: false,
                    edited: false,
                    show: true,
                    isNewColumn: false,
                    qIndex: 0,
                    isQuarter,
                },
                benchmarkId ?? targetBenchmark?.benchmarkId ?? null,
            );

            newManagerDataWithPrefills[rowIndex].performance.push(copiedPerformanceItem);
        } else {
            let getPeriod = period;
            if (isQuarter) {
                const switchObject: Record<string, string> = {
                    Q1: 'Mar',
                    Q2: 'Jun',
                    Q3: 'Sep',
                    Q4: 'Dec',
                };
                getPeriod = switchObject[period];
            }

            const daysInMonth = dayjs(`${this.selectedYear}-${getPeriod}-01`).daysInMonth();
            const buildEffectiveDate = dayjs(`${this.selectedYear} ${getPeriod} ${daysInMonth}`, 'YYYY MMM DD').format('YYYY-MM-DDT00:00:00');

            const newBasicPerformanceItem = new PerformanceItem<''>(
                buildEffectiveDate,
                period,
                null,
                tickerId,
                '',
                type,
                false,
                {
                    new: true,
                    edited: false,
                    show: true,
                    isNewColumn: false,
                    qIndex: 0,
                    isQuarter,
                },
                benchmarkId ?? targetBenchmark?.benchmarkId ?? null,
            );

            newManagerDataWithPrefills[rowIndex].performance.push(newBasicPerformanceItem);
        }
    }

    get showUnSavedMessage (): boolean {
        return this.$store.state.showUnSavedMessage;
    }

    get unsavedData (): boolean {
        return this.$store.state.unsavedData;
    }

    get isManagerDataAvailable (): boolean {
        return this.performanceDataWithPrefills.length > 0;
    }

    get years (): number[] {
        const startDate = dayjs().year();
        const yearArray = [];
        const max = 10;
        for (let i = 0; i < max; i++) {
            yearArray.push(startDate - i);
        }
        return yearArray;
    }

    public updatePerformanceValue (emittedData: {
        item: PerformanceItem<string | number>;
        rowIndex: number;
        itemIndex: number;
    }): void {
        Vue.set(
            this.performanceDataWithPrefills[emittedData.rowIndex].performance,
            emittedData.itemIndex,
            emittedData.item
        );
    }

    public closeUnsavedPopUp (): void {
        this.$store.commit('updateShowUnSavedMessage', false);
    }

    public getManagerIds (): Array<number> {
        return this.performanceDataWithPrefills.map(x => x.id);
    }


    @Watch('dataToBeSaved')
    private setUnsavedData (dataToBeSaved: PerformanceType[]) {
        if (!dataToBeSaved) return;
        this.$store.commit('updateUnsavedData', dataToBeSaved.length > 0);
    }
}
